import React, { cloneElement, Children, useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './columns.module.css';

const Columns = ( { children, separated } ) => {
	const mapChildren = useCallback( () => Children.map( children, child => {
		const className = child.props.className ? `${ styles.colWrapper } ${ child.props.className }` : styles.colWrapper;
		const props = { ...child.props, className };

		return (
			<div className={ styles.col }>
				{ cloneElement( child, props ) }
			</div>
		);
	} ), [ children ] );

	const cols = `cols${ children.length }`;

	return (
		<div className={ `${ styles.columns } ${ styles[ cols ] } ${ separated ? styles.separated : '' }` }>
			{ mapChildren() }
		</div>
	);
};

Columns.propTypes = {
	children: PropTypes.node.isRequired,
	separated: PropTypes.bool
};

export default Columns;
