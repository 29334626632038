import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import useShows from '../hooks/useShows';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Columns from '../components/Columns';
import Shows from '../components/Shows';
import AlbumThumbnail from '../components/AlbumThumbnail';

import styles from './home.module.css';

const Home = ( { data } ) => {
	const {
		file: {
			childMarkdownRemark: {
				frontmatter: {
					hero: {
						image: { childImageSharp: { fluid: hero } },
						alignment
					},
					featuredAlbum: {
						sectionHeader: faSectionHeader,
						message: faMessage,
						album: {
							frontmatter: { frontCover: { childImageSharp: { fluid: albumCover } } },
							parent: { name: albumId }
						},
						linkText: faLinkText
					},
					upcomingShows: {
						sectionHeader: usSectionHeader,
						emptyMessage,
						limit,
						linkText: usLinkText
					}
				},
				html: body
			}
		}
	} = data;

	const upcomingShows = useShows( { limit, upcoming: true } );

	return (
		<Layout>
			{	hero
				? <Hero alignment={ alignment } fluid={ hero } />
				: null
			}
			{ body
				? <section className="separated">
					<div className="wrapper">
						<div className="body message" dangerouslySetInnerHTML={ { __html: body } } />
					</div>
				</section>
				: null
			}
			<section>
				<Columns separated>
					<div className={ styles.featuredAlbum }>
						<h3 className="section-header">{ faSectionHeader }</h3>
						{ faMessage ? <p>{ faMessage }</p> : null }
						<AlbumThumbnail className={ styles.albumThumbnail } cover={ albumCover } id={ albumId } />
						<Link className="more-link" to="/music"><small>{ faLinkText || <>all music &rarr;</> }</small></Link>
					</div>
					<div className={ styles.upcomingShows }>
						<h3 className="section-header">{ usSectionHeader }</h3>
						<Shows
							className={ styles.shows }
							emptyMessage={ emptyMessage }
							shows={ upcomingShows }
							upcoming={ true }
						/>
						<Link className="more-link" to="/shows"><small>{ usLinkText || <>all shows &rarr;</> }</small></Link>
					</div>
				</Columns>
			</section>
		</Layout>
	);
};

Home.propTypes = { data: PropTypes.object };

export default Home;

export const query = graphql`
	query Home {
		file(relativePath: {eq: "pages/home.md"}) {
			childMarkdownRemark {
				frontmatter {
					hero {
						image {
							childImageSharp {
								fluid(maxWidth: 3000, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						alignment
					}
					featuredAlbum {
						sectionHeader
						message
						album {
							frontmatter {
								frontCover {
									childImageSharp {
										fluid(maxWidth: 500) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
							parent {
								... on File {
									name
								}
							}
						}
						linkText
					}
					upcomingShows {
						sectionHeader
						emptyMessage
						limit
						linkText
					}
				}
				html
			}
		}
	}
`;
