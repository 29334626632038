import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import styles from './album-thumbnail.module.css';

const AlbumThumbnail = ( { className, cover, id } ) => {
	return (
		<div className={ `${ styles.albumThumbnail } ${ className }` }>
			<Link
				className={ styles.albumCover }
				to={ `/music/${ id }` }
			>
				<Img
					style={ { height: '100%' } }
					fluid={ cover }
				/>
			</Link>
		</div>
	);
};

AlbumThumbnail.defaultProps = { className: '' };

AlbumThumbnail.propTypes = {
	className: PropTypes.string,
	cover: PropTypes.object.isRequired,
	id: PropTypes.string.isRequired
};

export default AlbumThumbnail;
